// Libraries
import { Controller } from '@hotwired/stimulus';

// Utilities
import { getCookie, setCookie } from '../utilities/cookie';

/**
 * @class CookieBannerController
 * @description Describe the controllers utility.
 */
class CookieBannerController extends Controller {
    static values = {
        seen: String,
    };

    connect() {
        const cookieSeen = getCookie('cookieSeen');

        if (!cookieSeen) {
            this.element.classList.add('show');
        }
    }

    triggerClick() {
        this.element.classList.remove('show');
        setCookie('cookieSeen', 'shown', 10 * 365);
    }
}

// Exports
export default CookieBannerController;
